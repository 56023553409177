import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import TextInput from './ProfilePage/TextInput';
import * as SvgIcon from '../Components/SvgIcon';
import {Color, FlexCol, FlexColCenter, FlexRow} from '../Components/Widget';
import {brandIdToBrandName} from '../Domain/Brand';
import ProductGrid from '../Components/ProductGrid';
import {useDimension} from '../Hooks/AppHooks';
import {Actions} from '../Contexts/AppContext';
import useCalcProductGridLayout from '../Utils/useCalcProductGridLayout';
import RefineBar from '../Components/RefineBar';
import * as L from '../Utils/Lang';
import queryString from 'query-string';
import RectButton, {BUTTON_SKIN} from '../Components/RectButton';
import {
  SearchNoResultRecommendProducts,
  SearchResult,
} from '../Components/search/SearchArea';
import CustomNav from '../Components/CustomNav';
import useFetchProducts from '../Hooks/useFetchProducts';

const SearchPage = props => {
  const queryParams = queryString.parse(window.location.search);
  const key = `${queryParams.brand_id +
    queryParams.category_id +
    queryParams.collectionId +
    queryParams.keyword}`;
  return <Search key={key} queryParams={queryParams} />;
};

const Search = props => {
  const {queryParams} = props;
  const {mobile, tablet, desktop} = useDimension();

  const {brand_id, category_id, collection_id, keyword: _keyword} = queryParams;
  const productGridLayoutRef = useRef(null);
  const {itemWidth, itemPerRow} = useCalcProductGridLayout(
    productGridLayoutRef.current?.offsetWidth,
  );
  const [keyword, setKeyword] = useState(_keyword);

  const sortOptions = ['+price', '-price', '-created'];
  const [locators, setLocators] = useState({
    brandId: parseInt(brand_id),
    categoryId: parseInt(category_id),
    collectionId: parseInt(collection_id),
  });

  const [searchData, setSearchData] = useState(null);
  const isSearchEmpty = searchData?.brands.length <= 0 || !searchData;
  const fetchSearchData = useCallback(async word => {
    if (word.trim() === '') {
      setSearchData(null);
      return;
    }
    setSearchData(await Actions.fetchSearchData(word));
  }, []);
  const [isFocus, setIsFocus] = useState(false);

  const collectionIds = useMemo(() => {
    if (!searchData) {
      return [];
    }
    if (locators.brandId === 0) {
      return searchData.brands.reduce((acc, cur) => {
        cur.collections.forEach(c => {
          acc.push(c.id);
        });
        return acc;
      }, []);
    }
    if (locators.categoryId === 0) {
      return searchData.brands
        .reduce((acc, cur) => [...acc, ...cur.collections], [])
        .map(c => c.id);
    }
    if (locators.collectionId === 0) {
      return searchData.brands
        .find(brand => brand.id === locators.brandId)
        .collections.filter(
          collection => collection.category.id === locators.categoryId,
        )
        .map(collection => collection.id);
    }
    return locators.collectionId;
  }, [
    locators.brandId,
    locators.categoryId,
    locators.collectionId,
    searchData,
  ]);

  const {
    products,
    totalCount,
    sort,
    loadNextPage,
    selectedFilterCnt,
    filterOptions,
    updateFilter,
    updateSort,
  } = useFetchProducts({
    brand: brandIdToBrandName[locators.brandId] || null,
    collectionIds,
    isFetch: Array.isArray(collectionIds)
      ? collectionIds.length > 0
      : collectionIds,
    keyword: keyword,
  });

  useEffect(() => {
    fetchSearchData(keyword);
  }, [keyword, fetchSearchData, locators]);

  const onChange = async e => {
    const {value} = e.target;
    setKeyword(value);
    const regExp = /^[\u4e00-\u9fa50-9a-zA-Z]+$/;

    if (!regExp.test(value)) {
      return;
    }

    setLocators({brandId: 0, categoryId: 0, collectionId: 0});
    await fetchSearchData(value);
  };

  const onSubmit = async word => {
    setLocators({brandId: 0, categoryId: 0, collectionId: 0});
    await fetchSearchData(word);
  };

  if (!products) {
    return null;
  }

  return (
    <Fragment>
      {(mobile || tablet) && <CustomNav middle={<div>商品搜尋</div>} />}
      <Container mobile={mobile}>
        <TextInput
          type="search"
          style={{
            width: mobile ? '272px' : '656px',
            margin: '20px 10px',
            display: 'flex',
            alignItems: 'center',
          }}
          styleCss={
            '& > .container { display: flex; align-items: center; width: 100%;}'
          }
          value={keyword}
          onChange={onChange}
          onFocus={_e => {
            setIsFocus(true);
          }}
          onBlur={_e => {
            setIsFocus(false);
          }}
          onKeyUp={e => {
            const isEnter = e.keyCode === 13;
            if (isEnter) {
              onSubmit(keyword);
            }
          }}
          placeholder="在 TUN 搜尋"
          icons={{
            left: <SvgIcon.Search style={{marginBottom: '12px'}} />,
            right: (
              <SvgIcon.ArrowRight
                onClick={() => {
                  onSubmit(keyword);
                }}
                style={{marginBottom: '12px', cursor: 'pointer'}}
              />
            ),
          }}
        />
        <div style={{position: 'relative', width: '100%'}}>
          {isFocus && !isSearchEmpty && (
            <div
              style={{
                backgroundColor: Color.mainWhite,
                minHeight: '800px',
                overflowY: 'auto',
              }}>
              <SearchResult keyword={keyword} searchData={searchData} />
            </div>
          )}
          {isSearchEmpty && <SearchNoResultRecommendProducts />}

          {!isFocus && !isSearchEmpty && (
            <RefineBar
              sort={sort}
              sortOptions={sortOptions}
              filter={filterOptions}
              onUpdateSort={updateSort}
              onUpdateFilter={updateFilter}
              hint={!mobile && `${totalCount} 件商品`}
              selectedFilterCnt={selectedFilterCnt}
              brandMenuList={
                <BrandMenuList
                  searchData={searchData}
                  locators={locators}
                  setLocators={setLocators}
                  fetchData={fetchSearchData}
                  keyword={keyword}
                  sort={sort}
                />
              }
              style={{
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: mobile ? 'unset' : '56px',
              }}
            />
          )}

          {mobile && (
            <FlexColCenter
              style={{
                fontSize: 12,
                color: Color.mainDark_70,
                padding: '24px 0',
              }}>
              {totalCount} 件商品
            </FlexColCenter>
          )}

          {!isFocus && !isSearchEmpty && (
            <FlexRow
              style={{
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}>
              {desktop && (
                <BrandMenuList
                  searchData={searchData}
                  locators={locators}
                  setLocators={setLocators}
                  fetchData={fetchSearchData}
                  keyword={keyword}
                  sort={sort}
                />
              )}
              {products.length > 0 ? (
                <FlexCol
                  style={{
                    width: !desktop ? '100%' : 'calc(100% - 300px)',
                    height: '100%',
                    overflowY: 'auto',
                    alignItems: 'center',
                  }}
                  ref={productGridLayoutRef}>
                  <ProductGrid
                    extraStyle={{width: '100%'}}
                    itemPerRow={itemPerRow}
                    itemWidth={itemWidth}
                    products={products || []}
                  />
                  {products.length < totalCount && (
                    <RectButton
                      style={{marginBottom: 20}}
                      skin={BUTTON_SKIN.DEFAULT}
                      text="更多商品"
                      onClick={() => {
                        loadNextPage();
                      }}
                    />
                  )}
                </FlexCol>
              ) : (
                <FlexCol
                  style={{
                    width: mobile ? '100%' : 'calc(100% - 300px)',
                    height: '100%',
                    overflowY: 'auto',
                    alignItems: 'center',
                    color: Color.mainDark_70,
                  }}>
                  <div>未找到任何商品</div>
                  <div>請嘗試刪除部分篩選條件</div>
                </FlexCol>
              )}
            </FlexRow>
          )}
        </div>
      </Container>
    </Fragment>
  );
};

const BrandMenuList = props => {
  const {locators, setLocators, searchData} = props;

  const tree = useMemo(() => {
    if (!searchData) {
      return null;
    }
    return searchData.brands.map(brand => {
      return {
        id: brand.id,
        title: brand.title,
        brand_products_count: brand.brand_products_count,
        categories: brand.collections.reduce((acc, cur) => {
          const target = acc.find(category => category?.id === cur.category.id);
          if (target) {
            target.category_products_count += cur.collection_products_count;
          } else {
            acc.push({
              id: cur.category.id,
              title: cur.category.title,
              category_products_count: cur.collection_products_count,
              collections: brand.collections.filter(
                collection => collection.category.id === cur.category.id,
              ),
            });
          }
          return acc;
        }, []),
      };
    });
  }, [searchData]);

  const onBrandClick = (isBrandActive, brand) => () => {
    setLocators({
      ...locators,
      brandId: brand.id,
      categoryId: 0,
      collectionId: 0,
    });
  };

  const onCatClick = (isCatActive, cat) => e => {
    e.stopPropagation();
    setLocators({...locators, categoryId: cat.id, collectionId: 0});
  };

  const onCollectionClick = c => e => {
    e.stopPropagation();
    setLocators({...locators, collectionId: c.id});
  };

  const activeCategoryCollectionsLength = useMemo(() => {
    const length = tree
      ?.find(b => b.id === locators.brandId)
      ?.categories.find(category => category.id === locators.categoryId)
      ?.collections.length;
    if (!length) {
      return 0;
    }
    return length;
  }, [locators.brandId, locators.categoryId, tree]);

  if (!tree) {
    return null;
  }

  return (
    <StyledBrandMenuList>
      <div style={{width: '260px', marginBottom: '5px'}}>
        <div
          className={`list ${locators.brandId === 0 && 'active'}`}
          onClick={() => {
            setLocators({brandId: 0, categoryId: 0, collectionId: 0});
          }}>
          <span>All brands</span>
          <span>{`(${searchData?.all_brands_products_count})`}</span>
        </div>
      </div>

      {tree.map(b => {
        const isBrandActive = b.id === locators.brandId;
        const isBrandFontBold =
          b.id === locators.brandId &&
          locators.categoryId === 0 &&
          locators.collectionId === 0;

        return (
          <Fragment key={b.id}>
            <div
              onClick={onBrandClick(isBrandActive, b)}
              style={{width: '260px', marginBottom: '5px'}}
              key={b.id}>
              <div className={`list ${isBrandFontBold && 'active'}`}>
                <span>{b.title}</span>
                <span>{`(${b.brand_products_count})`}</span>
              </div>
              <AnimSubMenu
                items={b.categories.length + activeCategoryCollectionsLength}
                active={isBrandActive}>
                {b.categories.map(category => {
                  const isCatActive = category.id === locators.categoryId;
                  const isCatFontBold =
                    category.id === locators.categoryId &&
                    locators.collectionId === 0;
                  return (
                    <Fragment key={category.id}>
                      <div
                        onClick={onCatClick(isCatActive, category)}
                        className={`list submenu-level1 ${isCatFontBold &&
                          'active'}`}>
                        <span>{L.d(category.title)}</span>
                        <span>{`(${category.category_products_count})`}</span>
                      </div>
                      <AnimSubMenu
                        items={category.collections.length}
                        active={isCatActive}>
                        {category.collections.map(collection => {
                          const isCollectionActive =
                            collection.id === locators.collectionId;
                          return (
                            <div
                              onClick={onCollectionClick(collection)}
                              key={collection.id}
                              className={`list submenu-level2 ${isCollectionActive &&
                                'active'}`}>
                              <span>{L.d(collection.title)}</span>
                              <span>{`(${collection.collection_products_count})`}</span>
                            </div>
                          );
                        })}
                      </AnimSubMenu>
                    </Fragment>
                  );
                })}
              </AnimSubMenu>
            </div>
          </Fragment>
        );
      })}
    </StyledBrandMenuList>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => (props.mobile ? '0 20px' : '0 40px')};
  flex: 1;
`;

const StyledBrandMenuList = styled.div`
  & .list {
    display: flex;
    justify-content: space-between;
    text-align: left;
    cursor: pointer;
  }
  & .active {
    font-weight: bold;
  }
`;

const AnimSubMenu = styled.div`
  height: ${props => (props.active ? (props.items + 1) * 28 : 0)}px;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 200ms;
  padding-top: 5px;
  overflow: hidden;
  & .submenu-level1 {
    padding: 4px 0 4px 20px;
  }
  & .submenu-level2 {
    padding: 4px 0 4px 40px;
  }
`;

export default SearchPage;
